import React from "react";
import { Box, Typography, Link } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Effective Date: November 12, 2024
      </Typography>
      <Typography variant="body1" gutterBottom>
        Welcome to Mountain Lakes Bagels
      </Typography>
      <Typography variant="body2" gutterBottom>
        At <strong>Mountain Lakes Bagels Inc.</strong>, we value your privacy
        and are committed to protecting your personal information. This Privacy
        Policy outlines how we collect, use, and share information through our
        mobile application (“App”). By using our App, you agree to the
        practices described below.
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Information We Collect
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Personal Information
      </Typography>
      <Typography variant="body2" gutterBottom>
        We may collect the following personal information to provide our
        services:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2">
            <strong>Contact Details:</strong> Including your name, email
            address, phone number, and address, which help with account setup,
            customer service, and order delivery.
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Location Data:</strong> If enabled, we may use your location
            to offer relevant location-based services, like nearby offerings
            and delivery options.
          </Typography>
        </li>
      </ul>
      <Typography variant="subtitle1" gutterBottom>
        Non-Personal Information
      </Typography>
      <Typography variant="body2" gutterBottom>
        We may also collect certain non-personal data to improve the App:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2">
            <strong>Device Information:</strong> Such as your device type and
            operating system to ensure compatibility and enhance functionality.
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Usage Data:</strong> Information about how you interact with
            the App, including accessed features and usage frequency, to
            optimize performance and user experience.
          </Typography>
        </li>
      </ul>

      <Typography variant="h6" gutterBottom>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body2" gutterBottom>
        We use your information to:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2">
            Operate and improve the App’s features and functionality.
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            Provide a personalized experience and communicate important updates,
            promotions, or support messages.
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            Enhance security, prevent unauthorized access, and detect any
            fraudulent activities.
          </Typography>
        </li>
      </ul>

      <Typography variant="h6" gutterBottom>
        3. Sharing and Disclosure of Information
      </Typography>
      <Typography variant="body2" gutterBottom>
        We respect your privacy and limit data sharing as follows:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2">
            <strong>Service Providers:</strong> We may work with trusted
            third-party providers who assist in operating the App, analyzing
            data, and supporting users. These providers are bound to use your
            data only as necessary to perform services on our behalf.
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Legal Obligations:</strong> We may disclose your information
            if required to comply with legal requirements or respond to lawful
            requests.
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Business Transfers:</strong> If Mountain Lakes Bagels Inc.
            is involved in a merger, acquisition, or asset sale, your
            information may be transferred as part of that transaction, in
            accordance with this Privacy Policy.
          </Typography>
        </li>
      </ul>

      <Typography variant="h6" gutterBottom>
        4. Data Security and Retention
      </Typography>
      <Typography variant="body2" gutterBottom>
        We take data security seriously, implementing industry-standard
        measures to protect your information. While no system can be entirely
        secure, we strive to maintain a high level of data protection. Your
        information is retained only as long as necessary for the purposes
        outlined in this Privacy Policy.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Your Privacy Rights
      </Typography>
      <Typography variant="body2" gutterBottom>
        Depending on your jurisdiction, you may have rights regarding your
        personal data, including:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2">
            Accessing, updating, or requesting a copy of your data.
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            Requesting deletion of your data, subject to legal requirements.
          </Typography>
        </li>
      </ul>

      <Typography variant="h6" gutterBottom>
        6. Cookies and Tracking Technologies
      </Typography>
      <Typography variant="body2" gutterBottom>
        Our App may use cookies and similar technologies to analyze
        performance, improve functionality, and provide personalized content.
        You can control the use of these technologies through your device’s
        settings.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body2" gutterBottom>
        We may periodically update this Privacy Policy to reflect changes in
        our practices or legal requirements. Any significant changes will be
        posted here, along with an updated effective date. We encourage you to
        review this policy periodically.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body2" gutterBottom>
        For questions or concerns regarding this Privacy Policy or to exercise
        your privacy rights, please contact us at:
      </Typography>
      <Typography variant="body2" gutterBottom>
        Mountain Lakes Bagels Inc. <br />
        350 US-46 <br />
        Mountain Lakes, NJ 07046 <br />
        Email: <Link href="mailto:bagelbob1957@aol.com">bagelbob1957@aol.com</Link> <br />
        Phone: 973-216-5072
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
