import Login from "Components/Login/Login";
import ProtectedRoutes, {
  RedirectIfLoggedIn,
} from "Components/ProtectedRoutes/ProtectedRoutes";
import Dashboard from "pages/Dashboard/Dashboard";
import MainLayout from "pages/layouts/MainLayout";
import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Categories from "pages/Categories/Categories";
import CategoryDetails from "pages/Categories/CategoryDetails/CategoryDetails";
// import ForgotPassword from "Components/ForgotPassword/ForgotPassword";
// import ResetPassword from "Components/ResetPassword/ResetPassword";
import FoodItems from "pages/FoodItems/FoodItems";
import AddOns from "pages/AddOns/AddOns";
import FoodItemsCarousels from "pages/FoodItemsCarousels/FoodItemsCarousels";
import AddOnss from "./pages/FoodItems/AddOns/AddOns";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";

export default function MyRoutes() {
  const [userData, setUserData] = useState<any>(null);

  function saveUser() {
    let encodedToken = localStorage.getItem("userToken");
    let decoded = jwtDecode(encodedToken);
    setUserData(decoded);
  }

  return (
    <>
      <Routes>
        {/* <Route path="/login" element={<Login saveUser={saveUser} />} /> */}
        {/* <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} /> */}
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />

        <Route
          path="/login"
          element={
            <RedirectIfLoggedIn>
              <Login saveUser={saveUser} />
            </RedirectIfLoggedIn>
          }
        />

        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <MainLayout userData={userData} setUserData={setUserData} />
            </ProtectedRoutes>
          }
        >
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/categories"
            element={
              <ProtectedRoutes>
                <Categories />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/category/:id"
            element={
              <ProtectedRoutes>
                <CategoryDetails />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fooditems"
            element={
              <ProtectedRoutes>
                <FoodItems />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/copiedaddons/:id"
            element={
              <ProtectedRoutes>
                <AddOnss />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/addons"
            element={
              <ProtectedRoutes>
                <AddOns />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/fooditemscarousels"
            element={
              <ProtectedRoutes>
                <FoodItemsCarousels />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="/warehouses/new"
            element={
              <ProtectedRoutes>
                <AddWarehouse />
              </ProtectedRoutes>
            }
          /> */}
          {/* <Route path="/clients" element={<Clients />} /> */}
          {/* <Route path="/warehouses/new" element={<AddClient />} /> */}
          {/* <Route path="/test" element={<Test />} /> */}
          {/* <Route path="/test"></Route> */}
          {/* <Route path="/test/:supplyId" element={<Test categories=your categories data />} /> */}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
      </Routes>
    </>
  );
}
